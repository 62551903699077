<template>
	<div class="c-app-form__step">
		<teraform-help-panel :panel="content.Step4Help" />

		<form id="app-form-step-4"
			  class="c-form c-app-form__form js-app-form__step"
			  method="post"
			  action="."
			  @submit.prevent="actions.validateWithErrorLogging($event, actions.submitForm(), stepInputs[state.step])"
			  novalidate>
			<!-- Browsers bind the "enter" key in a form containing buttons to click the first button. Since the first button in this form is not a submit button, put this inaccessible button here to make sure "enter" tries to submit the form as usual. -->
			<button class="u-visuallyhidden" tabindex="-1" aria-hidden="true">Submit</button>

			<h2 class="c-app-form__step-title">{{content.Step4Title}}</h2>
			<p class="c-app-form__step-intro" v-show="conditions.isLoggedOut()">All fields are required unless specified otherwise</p>

			<div class="c-app-form__alert" v-if="content.Step4Introduction">
				<div class="c-notification">
					<div class="c-notification__bar">
						<span class="c-notification__icon iconf-information" aria-hidden="true"></span>
					</div>
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step4Introduction" />
					</div>
				</div>
			</div>

			<div class="c-app-form__alert" v-if="content.Step4LoggedInIntroduction" v-show="conditions.isLoggedIn()">
				<div class="c-notification">
					<div class="c-notification__bar">
						<span class="c-notification__icon iconf-information" aria-hidden="true"></span>
					</div>
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step4LoggedInIntroduction" />
					</div>
				</div>
			</div>

			<!--SECURITY-->
			<section class="c-form__section" v-show="!conditions.isStepLocked('4-security')">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="SecurityType-0">{{content.FieldLabelSecurityType}}</label>
					</h3>
					<teraform-tooltip :text="content.FieldTooltipSecurityType" />
				</div>

				<div class="c-form__field">
					<teraform-radio :input="inputs.SecurityType" />
				</div>

				<div class="c-form__field" v-if="inputs.VehicleRegistrationNumber.condition()">
					<teraform-text :input="inputs.VehicleRegistrationNumber" />
				</div>

				<div class="c-form__field" v-if="inputs.VehicleVinNumber.condition()">
					<teraform-text :input="inputs.VehicleVinNumber" />
				</div>

				<div v-if="inputs.VehicleRegistrationNumber.condition() === false && !!(inputs.SecurityType.value)" class="c-form__field">
					<div class="o-body-text" v-html="content.FieldOtherTextSecurityType" />
				</div>
			</section>

			<section class="c-form__section" v-if="conditions.isStepLocked('4-security')">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">{{content.FieldLabelSecurityType}}</h3>
					<teraform-tooltip :text="content.FieldTooltipSecurityType" />
				</div>

				<div v-if="!conditions.hasSecurityData()">
					<div class="">{{content.FieldMissingMessageCurrentSecurity}}</div>
					<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__add" @click="actions.unlockStep($event, '4-security')">
						<span class="o-btn__icon iconf-plus"></span>
						<span class="o-btn__text">Add</span>
					</button>
				</div>

				<div v-if="conditions.hasSecurityData()">
					<div class="c-form__field c-form__field--close c-form__field--row">
						<span class="c-form__label" id="SecurityTypeLabel">{{content.FieldLabelCurrentSecurity}}</span>
						<span class="c-form__input c-form__input--readonly" aria-labelledby="SecurityTypeLabel">{{inputs.SecurityType.value}}</span>
					</div>
					<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__change" @click="actions.unlockStep($event, '4-security')">
						<span class="o-btn__icon iconf-edit"></span>
						<span class="o-btn__text">Change</span>
					</button>
				</div>
			</section>
			<!--SECURITY-->
			<!--EMPLOYMENT-->
			<section class="c-form__section" v-show="!conditions.isStepLocked('4-employment')">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="EmploymentStatus-0">{{content.SectionLabelEmployment}}</label>
					</h3>
					<teraform-tooltip :text="content.SectionTooltipEmployment" />
				</div>

				<div class="c-form__field">
					<teraform-select :input="inputs.EmploymentStatus" />
				</div>

				<div class="c-form__field" v-if="conditions.isEmployed()">
					<teraform-select :input="inputs.EmploymentIndustry" />
				</div>

				<div class="c-form__field" v-if="conditions.isEmployed()">
					<teraform-text :input="inputs.EmploymentJobRole" />
				</div>

				<div class="c-form__field" v-if="conditions.isEmployed()">
					<teraform-text :input="inputs.EmploymentCompany" />
				</div>

				<div class="c-form__field" v-if="conditions.isEmployed()">
					<teraform-text :input="inputs.EmploymentArea" />
				</div>

				<div class="c-form__field" v-if="conditions.isEmployed()">
					<teraform-text :input="inputs.EmploymentWorkPhoneNumber" />
				</div>

				<div class="c-form__field c-form__field-split" v-if="conditions.isEmployed()">
					<label for="EmploymentTimeMonth" class="c-form__label">{{content.FieldLabelEmploymentTime}}</label>

					<div class="c-form__field">
						<teraform-select :input="inputs.EmploymentTimeMonth" />
					</div>
					<div class="c-form__field">
						<teraform-select :input="inputs.EmploymentTimeYear" />
					</div>

					<span class="c-form__error" v-if="inputs.EmploymentTimeMonth.error || inputs.EmploymentTimeYear.error">Please select both month and year</span>
				</div>
			</section>

			<section class="c-form__section" v-show="conditions.requirePreviousEmploymentValue()">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="PreviousEmploymentStatus-0">{{content.SectionLabelPreviousEmployment}}</label>
					</h3>
				</div>

				<div class="c-form__field">
					<teraform-select :input="inputs.PreviousEmploymentStatus" />
				</div>

				<div class="c-form__field" v-if="conditions.isPreviousEmployed()">
					<teraform-select :input="inputs.PreviousEmploymentIndustry" />

				</div>

				<div class="c-form__field" v-if="conditions.isPreviousEmployed()">
					<teraform-text :input="inputs.PreviousEmploymentJobRole" />
				</div>

				<div class="c-form__field" v-if="conditions.isPreviousEmployed()">
					<teraform-text :input="inputs.PreviousEmploymentCompany" />
				</div>

				<div class="c-form__field" v-if="conditions.isPreviousEmployed()">
					<teraform-text :input="inputs.PreviousEmploymentArea" />
				</div>

				<div class="c-form__field" v-if="conditions.isPreviousEmployed()">
					<teraform-text :input="inputs.PreviousEmploymentWorkPhoneNumber" />
				</div>

				<div class="c-form__field c-form__field-split" v-if="conditions.isPreviousEmployed()">
					<label for="PreviousEmploymentTimeMonth" class="c-form__label">{{content.FieldLabelPreviousEmploymentTime}}</label>

					<div class="c-form__field">
						<teraform-select :input="inputs.PreviousEmploymentTimeMonth" />
					</div>
					<div class="c-form__field">
						<teraform-select :input="inputs.PreviousEmploymentTimeYear" />
					</div>

					<span class="c-form__error" v-if="inputs.PreviousEmploymentTimeMonth.error || inputs.PreviousEmploymentTimeYear.error">Please select both month and year</span>
				</div>
			</section>

			<section class="c-form__section" v-if="conditions.isStepLocked('4-employment')">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">{{content.SectionLabelEmployment}}</h3>
					<teraform-tooltip :text="content.SectionTooltipEmployment" />
				</div>

				<div v-if="!conditions.hasEmploymentData()">
					<div class="">{{content.FieldMissingMessageEmployment}}</div>
					<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__add" @click="actions.unlockStep($event, '4-employment')">
						<span class="o-btn__icon iconf-plus"></span>
						<span class="o-btn__text">Add</span>
					</button>
				</div>

				<div v-if="conditions.hasEmploymentData()">
					<div class="c-form__field c-form__field--close c-form__field--row">
						<span class="c-form__label" id="EmploymentStatusLabel">{{content.FieldLabelLoggedInEmploymentStatus}}</span>
						<span class="c-form__input c-form__input--readonly" aria-labelledby="EmploymentStatusLabel">{{inputs.EmploymentStatus.displayValue}}</span>
					</div>
					<div class="c-form__field c-form__field--close c-form__field--row" v-if="conditions.isEmployed()">
						<span class="c-form__label" id="EmploymentIndustryLabel">{{content.FieldLabelEmploymentIndustry}}</span>
						<span class="c-form__input c-form__input--readonly" aria-labelledby="EmploymentIndustryLabel">{{inputs.EmploymentIndustry.displayValue}}</span>
					</div>
					<div class="c-form__field c-form__field--close c-form__field--row" v-if="conditions.isEmployed()">
						<span class="c-form__label" id="EmploymentJobRoleLabel">{{content.FieldLabelEmploymentJobRole}}</span>
						<span class="c-form__input c-form__input--readonly" aria-labelledby="EmploymentJobRoleLabel">{{inputs.EmploymentJobRole.value}}</span>
					</div>
					<div class="c-form__field c-form__field--close c-form__field--row" v-if="conditions.isEmployed()">
						<span class="c-form__label" id="EmploymentCompanyLabel">{{content.FieldLabelEmploymentCompany}}</span>
						<span class="c-form__input c-form__input--readonly" aria-labelledby="EmploymentCompanyLabel">{{inputs.EmploymentCompany.value}}</span>
					</div>
					<div class="c-form__field c-form__field--close c-form__field--row" v-if="conditions.isEmployed()">
						<span class="c-form__label" id="EmploymentAreaLabel">{{content.FieldLabelLoggedInEmploymentArea}}</span>
						<span class="c-form__input c-form__input--readonly" aria-labelledby="EmploymentAreaLabel">{{inputs.EmploymentArea.value}}</span>
					</div>
					<div class="c-form__field c-form__field--close c-form__field--row" v-if="conditions.isEmployed()">
						<span class="c-form__label" id="EmploymentWorkPhoneNumberLabel">{{content.FieldLabelEmploymentWorkPhoneNumber}}</span>
						<span class="c-form__input c-form__input--readonly" aria-labelledby="EmploymentWorkPhoneNumberLabel">{{inputs.EmploymentWorkPhoneNumber.value}}</span>
					</div>
					<div class="c-form__field c-form__field--close c-form__field--row" v-if="conditions.isEmployed()">
						<span class="c-form__label" id="Label">{{content.FieldLabelLoggedInEmploymentTime}}</span>
						<span class="c-form__input c-form__input--readonly" aria-labelledby="Label">{{inputs.EmploymentTimeMonth.value}}/{{inputs.EmploymentTimeYear.value}}</span>
					</div>

					<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__change" @click="actions.unlockStep($event, '4-employment')">
						<span class="o-btn__icon iconf-edit"></span>
						<span class="o-btn__text">Change</span>
					</button>
				</div>
			</section>
			<!--/EMPLOYMENT-->
			<!--INCOME-->
			<section class="c-form__section" v-if="inputs.Income.condition()">
				<h3 class="c-form__section-title">{{inputs.Income.label}}</h3>
				<p v-if="inputs.Income.description">{{inputs.Income.description}}</p>
				<teraform-income :input="inputs.Income" />
			</section>
			<!--/INCOME-->
			<!--IMPENDING INCOME CHANGE-->
			<section class="c-form__section">
				<h3 class="c-form__section-title">{{content.FieldLabelImpendingIncomeChange}}</h3>

				<div class="c-form__field">
					<teraform-radio :input="inputs.ImpendingIncomeChange" />
				</div>

				<div class="c-form__field" v-show="inputs.IncomeChangeReason.condition()">
					<teraform-textarea :input="inputs.IncomeChangeReason" />
				</div>
			</section>
			<!--/IMPENDING INCOME CHANGE-->
			<!--ILLION-->
			<section class="c-form__section" v-if="inputs.BrokerIllionSwitch.condition()">
				<h3 class="c-form__section-title">@Model.FieldLabelBrokerIllionSwitch</h3>

				<div class="c-form__field">
					<teraform-radio :input="inputs.BrokerIllionSwitch" />
				</div>

				<div class="c-form__field" v-if="inputs.BrokerIllionDocumentId.condition()">
					<teraform-text :input="inputs.BrokerIllionDocumentId" />
				</div>

				<div class="c-form__field" v-show="inputs.BrokerIllionIframe.condition()">
					<teraform-illion :input="inputs.BrokerIllionIframe" />
				</div>

				<div class="c-form__field" v-if="content.FieldTextBrokerIllionSwitchLater" v-show="conditions.willDoIllionLater()" v-html="content.FieldTextBrokerIllionSwitchLater" />
			</section>
			<!--/ILLION-->
			<!--ADDITIONAL INFORMATION-->
			<section class="c-form__section">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="AdditionalInformation">{{content.FieldLabelAdditionalInformation}}</label>
					</h3>
					<teraform-tooltip :text="content.FieldTooltipAdditionalInformation" />
				</div>

				<div class="c-form__field" v-if="conditions.isLoggedOut()">
					<teraform-countdown :input="inputs.AdditionalInformation" />
				</div>
				<div class="c-form__field" v-if="conditions.isLoggedIn()">
					<teraform-countdown :input="inputs.AdditionalInformation" />
				</div>
			</section>
			<!--/ADDITIONAL INFORMATION-->

			<div class="c-form__controls">
				<div class="c-form__controls-fallback" v-if="conditions.recaptchaFallbackRequired()">
					<div class="c-form__controls-fallback-error c-form__error" v-html="content.ReCaptchaMessage" />
					<div v-if="actions.initRecaptchaFallback()" id="recaptcha" class="g-recaptcha" data-theme="clean" data-size="normal" :data-sitekey="config.recaptcha.fallback"></div>
					<input type="hidden" name="g-Recaptcha-Fallback" value="true" />
				</div>

				<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__back" @click="actions.setStep(3)" :disabled="conditions.isBusy()">Back</button>
				<p class="c-form__controls-text c-app-form__submit-text">{{content.ApplicationSubmitStatement}}</p>
				<button class="o-btn o-btn--supporting o-btn--huge o-btn--nowrap c-app-form__submit js-overlay-fire" type="submit" :disabled="conditions.isBusy() || !conditions.canSubmitApplication()" :class="{'c-loader--small': conditions.isBusy()}">Submit Application</button>
			</div>

			<a class="c-form__exit"
			   :target="content.ReturnToPortalLink.Target"
			   :href="content.ReturnToPortalLink.Url"
			   v-if="conditions.isLoggedIn() && content.ReturnToPortalLink">
				{{content?.ReturnToPortalLink?.Name}}
			</a>

			<div class="c-confirmation__overlay fadeout" :class="{'fadein': conditions.isBusy()}">
				<div class="c-form__section-title">We are processing your application</div>
				<p>We are running some initial processing on your application. This should only take a minute.</p>
				<div class="loader-wrapper">
					<span class="loader"></span>
				</div>
			</div>

		</form>
	</div>
</template>
<script>
	let app = {
		name: 'teraform-step4',
		props: [
			'inputs',
			'content',
			'actions',
			'state',
			'conditions',
			'stepInputs',
			'localState',
			'config',
		],
		data() {
			return {}
		},
		mounted() {
			let $scope = {
				inputs: this.inputs,
				content: this.content,
				actions: this.actions,
				state: this.state,
				conditions: this.conditions
			};
			//console.log('teraform-step4', $scope);
		},
		methods: {}
	};
	export default app;
</script>