////////////////
// Every page //
////////////////

import $ from 'jquery';
import 'Vue/vue-router';
import { NavLoad } from 'App/nav/load';
//'app/ui/track/load', // TODO-FE
import { Rotator } from 'App/rotator/rotator';
import { expandCollapse } from 'App/expand-collapse/expand-collapse';
import { Social as SocialShare } from 'App/social/share';
import { MapLoad } from 'App/map/map.load';
import { Cookie } from 'App/cookie/cookie';
import { modal } from 'App/modal/modal';
import { chat } from 'App/chat/chat';
import { BackToTop } from 'App/back-to-top/back-to-top';
import { sessionWarning } from 'App/session/warning';
import 'Node/jquery-match-height';
import 'tablescroll';
import { publish, subscribe } from 'Util/pubsub';
import { throttle } from 'Util/throttle';
import AOS from 'Node/aos';

var heroLoaded = false;
var homeHero = document.querySelector('.js-home-hero');

var loadHeroBg = function () {
	if (homeHero) {
		if (!heroLoaded && window.innerWidth >= 1024 && typeof (homeHero) !== 'undefined') {
			homeHero.classList.remove('is-loading');
			heroLoaded = true;
		}
	}
};

loadHeroBg();
subscribe('/window/resize', loadHeroBg);

//TrackLoad.init(); // TODO-FE
NavLoad.init();
SocialShare.init();
Cookie.init();
modal.init();
chat.init();
sessionWarning.init();

//if (document.querySelector('.js-loan-calculator')) {
//	import(/* webpackChunkName: "LoanCalc" */ 'App/application-form/calculator');
//}

//if (document.querySelector('.js-refer-form')) {
//	import(/* webpackChunkName: "PortalLoginRequestForm" */ 'App/form/form.refer').then(({ referForm }) => {
//		referForm.init();
//	});
//}

// Publish Resize event
const $window = $(window);
$window.on('resize', throttle(() => publish('/window/resize'), 250));
$window.on('scroll', () => publish('/window/scroll'));

// ExpandCollapse
//if (document.querySelector('.js-expand-collapse')) {
expandCollapse.init();
//}

// Video Macros
if (document.querySelector('.js-video-player')) {
	import(/* webpackChunkName: "VideoPlayer" */ 'App/video/video.player').then(({ VideoPlayer }) => {
		VideoPlayer.init();
	});
}

// Umbraco Forms
// init function will load required dependencies if an umbraco form is present
const $umbracoForms = $('.js-umbraco-form');
if ($umbracoForms.length) {
	import(/* webpackChunkName: "UmbracoForms" */ 'App/form/form.umbraco').then(({ UmbracoForms }) => {
		UmbracoForms.init();
	});
}

// Maps
const $maps = $('.js-map');
if ($maps.length) {
	MapLoad.init();
}

// Rotators
const $rotators = $('.js-rotator');
if ($rotators.length) {
	Rotator.init($rotators);
}

// Back to top
if ($('.js-footer__quicklinks').length) {
	subscribe('/window/scroll', function () {
		var newTopPos = $window.scrollTop();

		if ($(".js-loan-calculator").length) {
			if (newTopPos > (window.screen.height / 2)) {
				$('body').addClass('mobile-show-bottombar');
			}
			else {
				$('body').removeClass('mobile-show-bottombar');
			}
		} else {
			$('body').addClass('mobile-show-bottombar');
		}
	});
}

// Read More

$('#expandbtn').click(function () {
	$('#HeroTextReadMore').addClass("readmore");
	$('#expandbtn').hide();
});

//matchHeight
var equaliseGroup = function () {
	var $group = $(this);
	$group.find('.js-equalise').matchHeight();
};
$('.js-equalise-group').each(equaliseGroup);

// Check DOM for elements requiring JS
const $tables = $('.c-rte').find('table');
// Table Scroll
if ($tables.length) {
	$tables.tableScroll();
}

BackToTop.init();

//Global Animation based on scroll in view https://michalsnik.github.io/aos/
AOS.init({
	once: true,
	offset: 0
});

////////////////////////////
// page type replacements //
////////////////////////////

///// Common
import { Sticky } from 'App/sticky/sticky';
Sticky.init();

import { Tooltip } from 'App/tooltip/tooltip';
Tooltip.init();

import { AjaxReplace } from 'App/ajaxreplace/ajaxreplace'
AjaxReplace.init();

/*******************/
/* app/page/app-form-confirm */
//if (document.querySelector('.js-app-form__confirmation')) {
//	import(/* webpackChunkName: "LoanConfirmationForm" */ 'App/application-form/confirmation').then(({ confirmationForm }) => {
//		confirmationForm.init();
//	});
//}
/*******************/
/* app/page/app-form */
//if (document.querySelector('.js-app-form__form')) {
//	import(/* webpackChunkName: "LoanApplicationForm" */ 'App/application-form/application-form');
//}
/*******************/
/* app/page/branch-selector */
//if (document.querySelector('[ng-controller="app-branch-selector-controller"]')) {
//	import(/* webpackChunkName: "BranchSelector" */ 'App/application-form/branch-selector');
//}
/*******************/
/* app/page/detail */
import { lightbox } from 'App/lightbox/lightbox';
// Single and Multi Image Lightbox
if (document.querySelector('.js-lightbox-single, .js-lightbox-gallery')) {
	lightbox.init();
}
/*******************/
/* app/page/loan-payment */
//if (document.querySelector('.js-payment-form')) {
//	import(/* webpackChunkName: "PaymentForm" */ 'App/loan-payment/loan-payment').then(({ loanPayment }) => {
//		loanPayment.init();
//	});
//}
///*******************/
///* app/page/portal/**.* */
//if (document.querySelector('.js-customer-portal')) {
//	import(/* webpackChunkName: "CustomerPortal" */ 'Page/portal/customer-portal').then(({ CustomerPortal }) => {
//		CustomerPortal.init();
//	});
//}
//// exception - not actually in customer portal section
//if (document.querySelector('.js-portal-form__login-request')) {
//	import(/* webpackChunkName: "PortalLoginRequestForm" */ 'App/portal/login-request').then(({ loginRequestForm }) => {
//		loginRequestForm.init();
//	});
//}
/*******************/

//////////////////////////////////
// Any page, but not every page //
//////////////////////////////////

let overlay = $(".js-confirmation__overlay");
let random = Math.floor(Math.random() * 1000) + 2000;

if (overlay.length) {

	setTimeout(function () {
		overlay.addClass('fadeout');
	}, random);

}

let submitoverlay = $(".js-submitted__overlay");
let overlayfire = $(".js-overlay-fire");

if (submitoverlay.length) {

	overlayfire.addEventListener('click', function (e) {
		submitoverlay.removeClass('fadeout');
	});

}