<template>
	<div class="c-app-form__step">
		<teraform-help-panel :panel="content.Step2Help" />

		<form id="app-form-step-2"
			  class="c-form c-app-form__form js-app-form__step"
			  method="post"
			  action="."
			  @submit.prevent="actions.validateWithErrorLogging($event, actions.submitStep, stepInputs[state.step])"
			  novalidate>
			<!-- Browsers bind the "enter" key in a form containing buttons to click the first button. Since the first button in this form is not a submit button, put this inaccessible button here to make sure "enter" tries to submit the form as usual. -->
			<button class="u-visuallyhidden" tabindex="-1" aria-hidden="true">Submit</button>

			<h2 class="c-app-form__step-title">{{content.Step2Title}}</h2>
			<p class="c-app-form__step-intro" v-show="conditions.isLoggedOut()">All fields are required unless specified otherwise</p>

			<div class="c-app-form__alert" v-if="content.Step2Introduction">
				<div class="c-notification">
					<div class="c-notification__bar">
						<span class="c-notification__icon iconf-information" aria-hidden="true"></span>
					</div>
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step2Introduction" />
					</div>
				</div>
			</div>

			<div class="c-app-form__alert" v-if="content.Step2LoggedInIntroduction" v-show="conditions.isLoggedIn() && conditions.isDataCompleteStep2() === true">
				<div class="c-notification">
					<div class="c-notification__bar">
						<span class="c-notification__icon iconf-information" aria-hidden="true"></span>
					</div>
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step2LoggedInIntroduction" />
					</div>
				</div>
			</div>

			<div class="c-app-form__alert" v-if="content.Step2LoggedInIntroductionMissingFields" v-show="conditions.isLoggedIn() && conditions.isDataCompleteStep2() === false">
				<div class="c-notification o-portal-status--warning">
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step2LoggedInIntroductionMissingFields" />
					</div>
				</div>
			</div>

			<!--DOB AND GENDER-->
			<section class="c-form__section" v-show="conditions.isLoggedOut()">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">{{content.SectionLabelPersonalDetails}}</h3>
					<teraform-tooltip :text="content.SectionTooltipPersonalDetails" />
				</div>

				<teraform-anniversary :input="inputs.DateOfBirth" />

				<div class="c-form__field">
					<teraform-radio v-if="conditions.isLoggedOut()" :input="inputs.Gender" />
				</div>
			</section>
			<!--/DOB AND GENDER-->

			<!--CONTACT NUMBER-->
			<section class="c-form__section" v-show="conditions.isLoggedOut()">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="ContactNumberType">{{content.FieldLabelContactNumber}}</label>
					</h3>
					<teraform-tooltip :text="content.FieldTooltipContactNumber" />
				</div>

				<div class="c-form__field c-form__field--mid c-form__field-split">
					<div class="c-form__field u-2/5">
						<teraform-select :input="inputs.ContactNumberType" />
					</div>
					<div class="c-form__field u-3/5">
						<teraform-text :input="inputs.ContactNumber" />
					</div>
				</div>
			</section>
			<!--/CONTACT NUMBER-->

			<!--RELATIONSHIP STATUS-->
			<section class="c-form__section" v-if="!conditions.isStepLocked(2)">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="RelationshipStatus-0">{{content.FieldLabelRelationshipStatus}}</label>
					</h3>
					<teraform-tooltip :text="content.FieldTooltipRelationshipStatus" />
				</div>

				<div class="c-form__field">
					<teraform-radio :input="inputs.RelationshipStatus" />
				</div>

				<div class="c-form__field">
					<teraform-radio class="c-form__radio--block-label" :input="inputs.FinancialDependants" />
				</div>
			</section>

			<section class="c-form__section" v-if="conditions.isStepLocked(2)">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">{{content.FieldLabelRelationshipStatus}}</h3>
					<teraform-tooltip :text="content.FieldTooltipRelationshipStatus" />
				</div>

				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="RelationshipStatusLabel">{{content.FieldLabelLoggedInRelationshipStatus}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="RelationshipStatusLabel">
						<span v-if="conditions.hasValue(inputs.RelationshipStatus.value)">{{inputs.RelationshipStatus.value}}</span>
						<span v-if="!conditions.hasValue(inputs.RelationshipStatus.value)" class="c-form__missing">Unknown</span>
					</span>
				</div>
				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="FinancialDependantsLabel">{{content.FieldLabelLoggedInFinancialDependants}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="FinancialDependantsLabel">
						<span v-if="conditions.hasValue(inputs.FinancialDependants.value)">{{inputs.FinancialDependants.value}}</span>
						<span v-if="!conditions.hasValue(inputs.FinancialDependants.value)" class="c-form__missing">Unknown</span>
					</span>
				</div>
			</section>
			<!--/RELATIONSHIP STATUS-->

			<!--ADDRESS-->
			<section class="c-form__section" v-show="!conditions.isStepLocked(2)">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="AddressSearch">{{content.SectionLabelAddress}}</label>
					</h3>
					<teraform-tooltip :text="content.SectionTooltipAddress" />
				</div>

				<div class="c-form__field">
					<teraform-address :input="inputs.Address" />
				</div>

				<div class="c-form__field">
					<teraform-radio :input="inputs.AddressStatus" />
				</div>

				<div class="c-form__field c-form__field--close">
					<label class="c-form__label">{{content.FieldLabelAddressDuration}}</label>

					<div class="c-form__field c-form__field--mid c-form__field-split">
						<div class="c-form__field">
							<teraform-select :input="inputs.AddressDurationMonth" />
						</div>
						<div class="c-form__field">
							<teraform-select :input="inputs.AddressDurationYear" />
						</div>
					</div>
				</div>

				<div class="c-form__field" v-show="conditions.requirePreviousAddressValue()">
					<label class="c-form__label">{{content.FieldLabelPreviousAddressDuration}}</label>
					<div class="c-form__field c-form__field--mid c-form__field-split">
						<div class="c-form__field">
							<teraform-select :input="inputs.PreviousAddressMonth" />
						</div>
						<div class="c-form__field">
							<teraform-select :input="inputs.PreviousAddressYear" />
						</div>
					</div>
				</div>

			</section>

			<section class="c-form__section" v-show="conditions.isStepLocked(2)">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">{{content.SectionLabelAddress}}</h3>
					<teraform-tooltip :text="content.SectionTooltipAddress" />
				</div>

				<!--Needs to be kept in sync with vue/teraform/fields/address.vue-->
				<div class="c-form__field">
					<div class="c-form__autocomplete">
						<div class="c-form__autocomplete-selection">
							<p>
								{{inputs.Address.value.Unit + (inputs.Address.value.Unit ? ',' : '')}}
								<span v-if="!inputs.Address.value.BoxType">
									{{inputs.Address.value.StreetNumber}}
									{{inputs.Address.value.StreetName}}
								</span>
								<span v-if="inputs.Address.value.BoxType">
									{{inputs.Address.value.StreetName}}
									{{inputs.Address.value.StreetNumber}}
								</span>
								{{inputs.Address.value.StreetType}}<br />
								{{inputs.Address.value.Suburb}}<br v-if="inputs.Address.value.Suburb" />
								{{inputs.Address.value.City}}
								{{inputs.Address.value.Postcode}}
							</p>
						</div>
					</div>
				</div>

				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="AddressStatusLabel">{{content.FieldLabelAddressStatus}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="AddressStatusLabel">{{inputs.AddressStatus.value}}</span>
				</div>
				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="AddressDurationLabel">{{content.FieldLabelLoggedInAddressDuration}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="AddressDurationLabel">{{inputs.AddressDurationMonth.value}}/{{inputs.AddressDurationYear.value}}</span>
				</div>
			</section>
			<!--/ADDRESS-->

			<!--<section class="c-form__section" v-if="inputs.ExistingCustomer.condition()">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="ExistingCustomer-0">{{content.FieldLabelExistingCustomer}}</label>
					</h3>
				</div>
				<div class="c-form__field">
					<teraform-radio :input="inputs.ExistingCustomer" />
				</div>

				<div class="c-form__field" v-if="inputs.PreferredBranch.condition()">
					<teraform-select :input="inputs.PreferredBranch" />
					<p class="c-form__field-action" v-if="localState?.recommendBranchText">{{localState?.recommendBranchText}}</p>
				</div>
			</section>-->

			<div class="c-form__controls">
				<div class="c-form__controls-fallback" v-if="conditions.recaptchaFallbackRequired()">
					<div class="c-form__controls-fallback-error c-form__error" v-html="content.ReCaptchaMessage" />
					<div v-if="actions.initRecaptchaFallback()" id="recaptcha" class="g-recaptcha" data-theme="clean" data-size="normal" :data-sitekey="config.recaptcha.fallback"></div>
					<input type="hidden" name="g-Recaptcha-Fallback" value="true" />
				</div>

				<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__back" @click.prevent="actions.setStep(1)" :disabled="conditions.isBusy()">Back</button>
				<p class="c-form__controls-text c-app-form__submit-text">{{content.ApplicationSaveStatement}}</p>

				<div class="c-form__controls-group">
					<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__change" v-if="conditions.showChangeButtonStep2()" @click="actions.unlockStep($event, 2)">
						<span class="o-btn__icon iconf-edit"></span>
						<span class="o-btn__text">Change</span>
					</button>
					<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__change" v-if="conditions.showAddButtonStep2()" @click="actions.unlockStep($event, 2)">
						<span class="o-btn__icon iconf-plus"></span>
						<span class="o-btn__text">Add</span>
					</button>

					<button class="o-btn o-btn--supporting o-btn--large o-btn--nowrap c-app-form__submit" type="submit" :disabled="conditions.isBusy() || conditions.disableConfirmButtonStep2()" :class="{'c-loader--small': conditions.isBusy()}">
						<span v-if="conditions.isStepLocked(2)">Confirm and continue</span>
						<span v-if="!conditions.isStepLocked(2)">Next</span>
					</button>
				</div>
			</div>

			<a class="c-form__exit"
			   :target="content.ReturnToPortalLink.Target"
			   :href="content.ReturnToPortalLink.Url"
			   v-if="conditions.isLoggedIn() && content.ReturnToPortalLink">
				{{content?.ReturnToPortalLink?.Name}}
			</a>
		</form>

	</div>
</template>
<script>
	let app = {
		name: 'teraform-step2',
		props: [
			'inputs',
			'content',
			'actions',
			'state',
			'conditions',
			'stepInputs',
			'localState',
			'config',
		],
		data() {
			return {}
		},
		mounted() {
			let $scope = {
				inputs: this.inputs,
				content: this.content,
				actions: this.actions,
				state: this.state,
				conditions: this.conditions
			};
			//console.log('teraform-step2', $scope);
		},
		methods: {}
	};
	export default app;
</script>