<template>
	<div v-show="input.condition()" :id="input.htmlId">
		<fieldset v-for="item in input.proxy" @change="input.updateValues()">

			<div class="c-form__field" v-if="input.proxy.indexOf(item) > 0">
				<a @click.prevent="input.removeValue(item)" class="c-form__remove-button">
					<span class="icon iconf-minus"></span>Remove income
				</a>
			</div>

			<div class="c-form__field">
				<label class="c-form__label" :for="input.htmlId + 'Amount-' + input.proxy.indexOf(item)">
					{{input.content.amountLabel}}
					<teraform-tooltip :text="input.content.amountTooltip" :class="'c-tooltip--small'" />
				</label>
				
				<div class="c-form__currency-layout">
					<span class="c-form__currency-unit">$</span>
					<input :id="input.htmlId + 'Amount-' + input.proxy.indexOf(item)"
						   :name="input.htmlName + 'Amount-' + input.proxy.indexOf(item)"
						   type="number"
						   step="0.01"
						   min="1"
						   class="c-form__input c-form__currency-input"
						   :data-required="input.validation.required"
						   :class="{'has-error': item.errors['amount']}"
						   :disabled="input.disabled()"
						   v-model="item.amount" />
				</div>
			</div>

			<div class="c-form__field">
				<ul class="c-form__radio-list">
					<li class="c-form__radio-item" v-for="option in input.frequencyOptions">
						<label class="o-fake-input__wrapper">
							<input :id="input.htmlId +'Frequency-'+ input.proxy.indexOf(item) + '-' + input.frequencyOptions.indexOf(option)"
								   :name="input.htmlName +'Frequency-'+ input.proxy.indexOf(item)"
								   type="radio"
								   class="o-fake-input c-fake-radio"
								   :data-required="input.validation.required"
								   :value="option.value"
								   :disabled="input.disabled()"
								   v-model="item.frequency" />
							<span class="c-fake-radio__display" :class="{'has-error': item.errors['frequency']}">{{option.displayValue}}</span>
						</label>
					</li>
				</ul>
			</div>

			<div class="c-form__field">
				<label :for="input.htmlId + 'Source-' + input.proxy.indexOf(item)" class="c-form__label">
					{{input.content.sourceLabel}}
					<teraform-tooltip :text="input.content.sourceTooltip" :class="'c-tooltip--small'" />
				</label>
				<select :id="input.htmlId + 'Source-' + input.proxy.indexOf(item)"
						:name="input.htmlName"
						class="c-form__input"
						:data-required="input.validation.required"
						:class="{'has-error': item.errors['source']}"
						:disabled="input.disabled()"
						v-model="item.source">
					<option v-for="option in input.sourceOptions" :value="option.value">{{option.displayValue}}</option>
				</select>
			</div>

			<span class="c-form__error" v-if="input.errors.errorRequired" v-show="item.error === 'custom'">{{input.errors.errorRequired}}</span>
		</fieldset>

		<div class="c-form__field">
			<a @click.prevent="input.addValue()" class="c-form__add-button">
				<span class="icon iconf-plus"></span>Add other income
			</a>
		</div>

		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>

		<input type="hidden" :name="input.htmlId" :value="input.rawJson" />
	</div>
</template>
<script>
	import { ErrorTypes } from 'Vue/teraform/rules';

	let setup = function ($input) {
		$input.rawJson = '[]';

		$input.frequencyOptions = [{
			displayValue: 'Weekly',
			value: 'Weekly'
		}, {
			displayValue: 'Fortnightly',
			value: 'Fortnightly'
		}, {
			displayValue: 'Monthly',
			value: 'Monthly'
		}];

		if ($input.settings) {
			$input.sourceOptions = $input.settings.sourceOptions;
		}

		$input.addValue = function () {
			let template = {
				errors: {
					amount: false,
					frequency: false,
					source: false,
				},
				error: null,
				amount: null,
				frequency: null,
				source: null
			};
			$input.proxy.push(template);
		};

		$input.removeValue = function (item) {
			let index = Array.from($input.proxy).indexOf(item);

			if (index > -1) {
				let temp = Array.from($input.proxy);
				temp[index] = null;
				$input.proxy = temp.filter(x => x !== null);
				$input.updateValues();
			}
		};

		let validateProxyItems = function () {
			Array.from($input.proxy).map(x => {
				let hasError = false;
				x.errors = {
					amount: false,
					frequency: false,
					source: false,
				};

				if (x?.amount > 0) {
					x.errors['amount'] = false;
				} else {
					x.errors['amount'] = true;
					hasError = true;
				}

				if (x?.frequency) {
					x.errors['frequency'] = false;
				} else {
					x.errors['frequency'] = true;
					hasError = true;
				}

				if (x.source) {
					x.errors['source'] = false;
				} else {
					x.errors['source'] = true;
					hasError = true;
				}

				if (hasError === false) {
					x.error = null;
				} else {
					x.error = ErrorTypes.CUSTOM;
				}
			});

			if ($input.validation.required === false) {
				let first = $input.proxy[0];
				if (first.amount === null && first.frequency === null && first.source === null) {
					first.error = null;
					first.errors = {
						amount: false,
						frequency: false,
						source: false,
					};
				}
			}
		}

		$input.updateValues = function () {
			$input.value = [];
			validateProxyItems();

			Array.from($input.proxy).map(x => {
				if (x.error === null) {
					let item = {
						amount: x.amount,
						frequency: x.frequency,
						source: x.source
					};
					// console.log({ item });
					$input.value.push(item);
				}
			});

			$input.rawJson = JSON.stringify($input.value);

			// console.log({ _updateValues:'updateValues', value: $input.value });
		};

		$input.componentValidation = function (value) {
			var rules = $input.validation;

			let itemsWithError = Array.from($input.proxy).filter(x => x.error !== null);
			if (itemsWithError.length > 0) {
				return ErrorTypes.CUSTOM;
			}

			if (rules.required) {
				if (!value) {
					return ErrorTypes.REQUIRED;
				}
			}

			//console.log({
			//	_componentValidation: 'componentValidation',
			//	value,
			//	validation: $input.validation,
			//	$input
			//});

			return true;
		};

		$input.proxy = JSON.parse(JSON.stringify($input.value))
			.map(x => {
				x.errors = {};
				x.error = null;
				return x;
			});

		if ($input.proxy.length === 0) {
			$input.addValue();
		}
	};

	export default {
		name: 'teraform-income',
		props: ['input'],
		data() {
			return {}
		},
		beforeMount() {
			if (this.input.value === null || this.input.value === undefined) {
				this.input.value = [];
			}
		},
		mounted() {
			setup(this.input);
			// console.log('teraform-income', this.input);
		},
		methods: {
		}
	};
</script>