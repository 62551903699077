<template>
	<div v-show="input.condition()" :id="input.htmlId">
		<div class="c-form__field c-form__field--has-overlay" :class="{'is-active': input.state.resultsVisible}">
			<div class="c-form__autocomplete" :class="{'c-loader': input.state.busy}">
				<div class="js-address__autocomplete" role="combobox">
					<label class="c-form__label" :for="input.htmlId + 'Search'" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
					<label class="c-form__label" :for="input.htmlId + 'Search'" v-if="!input.hasLabel() && !(input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>

					<input class="c-form__input js-address__autocomplete-input"
						   type="text"
						   :id="input.htmlId + 'Search'"
						   :placeholder="input.placeholder"
						   autocomplete="off"
						   @input="input.actions.search(input.state.query)"
						   v-model="input.state.query"
						   :disabled="input.disabled()"
						   :class="{'has-error': input.error}"
						   role="textbox"
						   :aria-controls="input.htmlId + '-results'"
						   aria-autocomplete="list"
						   :aria-expanded="input.state.resultsVisible"
						   :aria-activedescendant="input.state.selectedAutocompleteResult" />

					<ul class="c-form__autocomplete-result-list"
						:id="input.htmlId + '-results'"
						role="listbox"
						v-show="input.state.results && input.state.resultsVisible"
						aria-live="off">
						<li v-for="(item, index) in input.state.results"
							@click="input.actions.select(item.Address, item.Id)"
							class="c-form__autocomplete-result-item js-address__result-item"
							tabindex="-1"
							:id="input.htmlId + '-result-' + index"
							:aria-selected="input.state.selectedAutocompleteResult === (input.name + '-result-' + index)"
							:data-autocomplete-address="item.Address"
							:data-autocomplete-id="item.Id">
							{{item.Address}}
						</li>
					</ul>
				</div>

				<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.state.specifyAddress !== true && input.error === 'required'">{{input.errors.errorRequired}}</span>
				<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.state.specifyAddress !== true && input.error === 'custom'">{{input.errors.errorCustom}}</span>
				<span class="c-form__error" v-if="input.errors.errorInvalid" v-show="input.state.specifyAddress !== true && input.error === 'invalid'">{{input.errors.errorInvalid}}</span>

				<!-- Needs to be kept in sync with Step2 view -->
				<div class="c-form__autocomplete-selection" v-if="input.state.specifyAddress !== true && input.value.StreetNumber">
					<p>
						{{input.value.Unit + (input.value.Unit ? ',' : '')}}
						<span v-if="!input.value.BoxType">
							{{input.value.StreetNumber}}
							{{input.value.StreetName}}
						</span>
						<span v-if="input.value.BoxType">
							{{input.value.StreetName}}
							{{input.value.StreetNumber}}
						</span>
						{{input.value.StreetType}}<br />
						{{input.value.Suburb}}<br v-if="input.value.Suburb" />
						{{input.value.City}}
						{{input.value.Postcode}}
					</p>
				</div>

				<div v-if="!input.state.specifyAddress && false" class="c-form__field-action">
					Not the right address?
					<button class="o-btn--link" type="button" @click="input.actions.setSpecific($event)">Edit Address</button>
				</div>
			</div>
		</div>
		<div v-if="input.searchUnavailable && input.state.addressError"></div>
		<div v-show="input.state.specifyAddress" class="c-form__field c-form__field--far c-form__field-split">
			<div class="c-form__field">
				<label :for="input.htmlId + 'Unit'" class="c-form__label" v-if="input.labelUnit">{{input.labelUnit}}<span class="c-form__required-optional">&nbsp;(optional)</span></label>
				<input :id="input.htmlId + 'Unit'"
					   :name="input.htmlName + 'Unit'"
					   type="text"
					   :placeholder="input.placeholderUnit"
					   class="c-form__input"
					   maxlength="100"
					   :class="{'has-error': input.errorParts['Unit']}"
					   :disabled="input.disabled()"
					   v-model="input.value.Unit"
					   @change="input.update()" />

				<span class="c-form__error" v-show="input.errorParts['Unit'] === 'pattern'">Numbers and letters only</span>
			</div>
			<div class="c-form__field">
				<label :for="input.htmlId + 'StreetNumber'" class="c-form__label" v-if="input.labelStreetNumber">{{input.labelStreetNumber}}</label>
				<input :id="input.htmlId + 'StreetNumber'"
					   :name="input.htmlName + 'StreetNumber'"
					   type="text"
					   :placeholder="input.placeholderStreetNumber"
					   class="c-form__input"
					   maxlength="100"
					   :required="input.validation.required"
					   :class="{'has-error': input.error || input.errorParts['StreetNumber']}"
					   :disabled="input.disabled()"
					   v-model="input.value.StreetNumber"
					   @change="input.update()" />

				<span class="c-form__error" v-if="input.errors.errorRequiredPart" v-show="input.error === 'required'">{{input.errors.errorRequiredPart}}</span>
				<span class="c-form__error" v-if="input.errors.errorInvalidPart" v-show="input.error === 'invalid'">{{input.errors.errorInvalidPart}}</span>
				<span class="c-form__error" v-show="input.errorParts['StreetNumber'] === 'pattern'">Numbers and hyphens only</span>
			</div>
			<div class="c-form__field">
				<label :for="input.htmlId + 'StreetName'" class="c-form__label" v-if="input.labelStreetName">{{input.labelStreetName}}</label>
				<input :id="input.htmlId + 'StreetName'"
					   :name="input.htmlName + 'StreetName'"
					   type="text"
					   :placeholder="input.placeholderStreetName"
					   class="c-form__input"
					   maxlength="100"
					   :required="input.validation.required"
					   :class="{'has-error': input.error || input.errorParts['StreetName']}"
					   :disabled="input.disabled()"
					   v-model="input.value.StreetName"
					   @change="input.update()" />

				<span class="c-form__error" v-if="input.errors.errorRequiredPart" v-show="input.error === 'required'">{{input.errors.errorRequiredPart}}</span>
				<span class="c-form__error" v-if="input.errors.errorInvalidPart" v-show="input.error === 'invalid'">{{input.errors.errorInvalidPart}}</span>
				<span class="c-form__error" v-show="input.errorParts['StreetName'] === 'pattern'">Numbers and letters only</span>
			</div>
			<div class="c-form__field">
				<label :for="input.htmlId + 'StreetType'" class="c-form__label" v-if="input.labelStreetType">{{input.labelStreetType}}<span class="c-form__required-optional">&nbsp;(optional)</span></label>
				<input :id="input.htmlId + 'StreetType'"
					   :name="input.htmlName + 'StreetType'"
					   type="text"
					   :placeholder="input.placeholderStreetType"
					   class="c-form__input"
					   maxlength="16"
					   :class="{'has-error': input.errorParts['StreetType']}"
					   :disabled="input.disabled()"
					   v-model="input.value.StreetType"
					   @input="input.update()" />

				<span class="c-form__error" v-show="input.errorParts['StreetType'] === 'pattern'">Letters only</span>
			</div>
			<div class="c-form__field">
				<label :for="input.htmlId + 'Suburb'" class="c-form__label" v-if="input.labelSuburb">{{input.labelSuburb}}<span class="c-form__required-optional">&nbsp;(optional)</span></label>
				<input :id="input.htmlId + 'Suburb'"
					   :name="input.htmlName + 'Suburb'"
					   type="text"
					   :placeholder="input.placeholderSuburb"
					   class="c-form__input"
					   maxlength="100"
					   :class="{'has-error': input.errorParts['Suburb']}"
					   :disabled="input.disabled()"
					   v-model="input.value.Suburb"
					   @input="input.update()" />

				<span class="c-form__error" v-show="input.errorParts['Suburb'] === 'pattern'">Letters only</span>
			</div>
			<div class="c-form__field">
				<label :for="input.inputId + 'City'" class="c-form__label" v-if="input.labelCity">{{input.labelCity}}</label>
				<input :id="input.htmlId + 'City'"
					   :name="input.htmlName + 'City'"
					   type="text"
					   :placeholder="input.placeholderCity"
					   class="c-form__input"
					   maxlength="40"
					   :required="input.validation.required"
					   :class="{'has-error': input.error || input.errorParts['City']}"
					   :disabled="input.disabled()"
					   v-model="input.value.City"
					   @input="input.update()" />

				<span class="c-form__error" v-if="input.errors.errorRequiredPart" v-show="input.error === 'required'">{{input.errors.errorRequiredPart}}</span>
				<span class="c-form__error" v-if="input.errors.errorInvalidPart" v-show="input.error === 'invalid'">{{input.errors.errorInvalidPart}}</span>
				<span class="c-form__error" v-show="input.errorParts['City'] === 'pattern'">Letters only</span>
			</div>
			<div class="c-form__field">
				<label :for="input.htmlId + 'Postcode'" class="c-form__label" v-if="input.labelPostcode">{{input.labelPostcode}}</label>
				<input :id="input.htmlId + 'Postcode'"
					   :name="input.htmlName + 'Postcode'"
					   type="text"
					   :placeholder="input.placeholderPostcode"
					   class="c-form__input"
					   maxlength="4"
					   :required="input.validation.required"
					   :class="{'has-error': input.error || input.errorParts['Postcode']}"
					   :disabled="input.disabled()"
					   v-model="input.value.Postcode"
					   @input="input.update()" />

				<span class="c-form__error" v-if="input.errors.errorRequiredPart" v-show="input.error === 'required'">{{input.errors.errorRequiredPart}}</span>
				<span class="c-form__error" v-if="input.errors.errorInvalidPart" v-show="input.error === 'invalid'">{{input.errors.errorInvalidPart}}</span>
				<span class="c-form__error" v-show="input.errorParts['Postcode'] === 'pattern'">Your postcode must be 4 numbers</span>
			</div>
		</div>
	</div>
</template>
<script>
	import $ from 'jquery';
	import * as Keybinding from 'Util/keybinding';
	import { debounce } from 'Util/debounce';
	import { publish } from 'Util/pubsub';
	import { ErrorTypes, Patterns } from 'Vue/teraform/rules';

	let config = (await fetch('/api/address-finder/config')).json().then(x => config = x);

	const selectors = {
		autocomplete: '.js-address__autocomplete',
		autocompleteInput: '.js-address__autocomplete-input',
		resultItem: '.js-address__result-item'
	};

	const dataAttributes = {
		address: 'autocomplete-address',
		id: 'autocomplete-id'
	};

	const ComponentErrorTypes = {
		INVALID: 'invalid',
		PART_PATTERN: 'part-pattern'
	};

	const partialPatterns = {
		Unit: Patterns.FREE_TEXT,
		StreetNumber: Patterns.STREET_NUM,
		StreetName: Patterns.STREET_NAME,
		StreetType: Patterns.ALPHA_SPACES,
		Suburb: Patterns.CITY_SUBURB,
		City: Patterns.CITY_SUBURB,
		Postcode: Patterns.POSTCODE
	}

	let module = {
		_decodeHtml: function (value) {
			let txt = document.createElement("textarea");
			txt.innerHTML = value;
			return txt.value;
		}
	};

	let setup = function ($input) {

		let app = {
			init: function () {
				if (!$input.value) {
					$input.value = {
						Unit: '',
						StreetNumber: '',
						StreetName: '',
						StreetType: '',
						Suburb: '',
						City: '',
						Postcode: '',
						Latitude: null,
						Longitude: null
					};

					//$input.update();
				}

				// partial errors in sub field components
				if (!$input.errorParts) {
					$input.errorParts = {
						Unit: null,
						StreetNumber: null,
						StreetName: null,
						StreetType: null,
						Suburb: null,
						City: null,
						Postcode: null
					};
				}

				// Default value
				if (typeof $input.postal === 'undefined') {
					$input.postal = false;
				}

				$input.state = {
					query: '',
					results: null,
					resultsVisible: false,
					selectedAutocompleteResult: null,

					busy: false,
					specifyAddress: false,
					addressError: false
				};

				$input.actions = app.actions;
				$input.actions._bindEvents();

				// Need to explicitly say HTML can be trusted or
				// AngularJS will refuse to render it
				$input.searchUnavailableHtml = module._decodeHtml($input.searchUnavailable);
			},
			actions: {
				search: debounce(function (value) {
					var url;

					if (value.length < 3) {
						$input.actions._hideResults();
						// Required because debounce decouples this function from AngularJS
						//$rootScope.$apply();
						return;
					}

					if ($input.postal) {
						url = config.addressFinder.searchPostal + '?query=' + encodeURI(value);
					} else {
						url = config.addressFinder.search + '?query=' + encodeURI(value);
					}

					fetch(url)
						.then(response => response.json())
						.then(data => $input.actions._searchCallback(data))
						.catch($input.actions._handleError);
				}, 300),

				_searchCallback: function (data) {
					console.log(data);

					if (data) {
						$input.state.results = data;
						$input.actions._showResults();
					} else {
						$input.actions._handleError();
					}
				},

				_handleError: function () {
					// Handle error
					console.log('handle error');
					$input.state.specifyAddress = true;
					$input.state.addressError = true;
					$input.state.query = '';
				},

				select: function (a, pxid) {
					$input.state.query = a;
					$input.state.results = null;
					$input.actions._hideResults();

					if ($input.state.busy === true) {
						return;
					}

					$input.state.busy = true;

					fetch(config.addressFinder.detail + '?id=' + encodeURI(pxid))
						.then(response => response.json())
						.then(data => $input.actions._selectCallback(data));
				},

				_selectCallback: function (data) {
					$input.state.busy = false;
					if (data) {
						var unitName;
						var street;
						var streetName;
						var streetType;

						// Unit, type, floor, building
						unitName = [];
						if (data.UnitType !== null && data.UnitIdentifier !== null) {
							unitName.push(data.UnitType + ' ' + data.UnitIdentifier);
						}
						if (data.Floor !== null) {
							unitName.push(data.Floor);
						}
						if (data.BuildingName !== null) {
							unitName.push(data.BuildingName);
						}
						unitName = unitName.join(', ');

						$input.value.Unit = unitName;

						if ($input.postal && data.BoxType) {
							// PO Box

							// Street number
							$input.value.StreetNumber = data.Number;

							// Street name
							$input.value.StreetName = data.BoxType;

							// Street type
							$input.value.StreetType = '';
						} else {
							// Street number
							$input.value.StreetNumber = data.Number;
							if (data.Alpha) {
								$input.value.StreetNumber += data.Alpha;
							}

							// Street name
							$input.value.StreetName = data.StreetName;
							if (!$input.value.StreetName) {
								// There's a bug in the API where sometimes the
								// StreetName is blank, but both Street and
								// StreetType are present
								if (data.Street && data.StreetType) {
									street = data.Street;
									streetType = data.StreetType;
									streetName = street.match(new RegExp('^(.*?)\\s+' + streetType, 'i'));
								}
								if (streetName) {
									$input.value.StreetName = streetName[1];
								}
							}

							// Street type
							// For some reason, street type always comes through as lower case
							if (data.StreetType) {
								$input.value.StreetType = data.StreetType.charAt(0).toUpperCase() + data.StreetType.slice(1);
							} else {
								$input.value.StreetType = '';
							}
						}

						// Suburb
						if ($input.postal && data.BoxType) {
							// PO Box
							$input.value.Suburb = data.LobbyName === data.Mailtown ? '' : data.LobbyName;
						} else {
							$input.value.Suburb = [data.Suburb === data.City ? '' : data.Suburb];

							if ($input.postal && data.RuralDeliveryNumber) {
								$input.value.Suburb.push('RD ' + data.RuralDeliveryNumber);
							}
							$input.value.Suburb = $input.value.Suburb.join(' ');
						}

						// City
						if ($input.postal && data.BoxType) {
							// PO Box
							$input.value.City = data.Mailtown || data.City;
						} else {
							$input.value.City = data.City;
						}

						// Postcode
						$input.value.Postcode = data.Postcode;

						// BoxType - only stored for determining display order
						$input.value.BoxType = data.BoxType;

						// Latitude & Longitude
						if (data.Lat && data.Long) {
							$input.value.Latitude = data.Lat;
							$input.value.Longitude = data.Long;
						}

						$input.liveValidate();
						$input.state.specifyAddress = false;
					} else {
						$input.actions._handleError();
					}
				},

				setSpecific: function (e) {
					e.preventDefault();
					$input.state.specifyAddress = true;
				},

				// jQuery bindings
				_bindEvents: function () {
					// Make sure this works correctly when there are
					// multiple address components on the page by
					// restricting it based on ID
					var prefix = '#' + $input.name + ' ';

					$(document).on('keydown', prefix + selectors.autocomplete, $input.actions._changeResultFocus);
					$(document).on('keydown', prefix + selectors.autocompleteInput, $input.actions._selectResultOnEnter);
					$(document).on('focusout', prefix + selectors.autocomplete, $input.actions._onUnfocus);
					$(document).on('focusin', prefix + selectors.autocomplete, $input.actions._onFocus);

					Keybinding.bind('escape', $input.actions._hideResultsOnEscape, true);
				},

				_changeResultFocus: function (e) {
					if (!e.key) {
						// Chrome fires a keydown event with no e.key when
						// selecting an option from the browser's built-in
						// autocomplete list
						return;
					}

					var key = e.key.toLowerCase();
					var $wrapper = $(e.target).closest(selectors.autocomplete);
					var $results = $wrapper.find(selectors.resultItem).filter(':visible');
					var $result;
					var index;
					var newIndex;
					var offset;

					if (key === 'arrowup' || key === 'up') {
						if (e.altKey && ($results.is(':visible') === true)) {
							$input.actions._hideResults();
							//$rootScope.$apply();
							return;
						}
						offset = -1;
					} else if (key === 'arrowdown' || key === 'down') {
						if (e.altKey && ($results.is(':visible') === false)) {
							$input.actions._showResults();
							//$rootScope.$apply();
							return;
						}
						offset = +1;
					} else {
						if (key === 'arrowleft' || key === 'left' || key === 'arrowright' || key === 'right' || key === 'home' || key === 'end') {
							// If moving the caret within the input, return focus
							$input.actions._removeResultFocus();
						}
						//$rootScope.$apply();
						return;
					}

					// Don't scroll with arrow press
					e.preventDefault();

					$result = $input.actions._getFocusEl().closest(selectors.resultItem);

					index = $results.index($result);
					newIndex = index + offset || 0;

					if (newIndex < 0) {
						newIndex = $results.length - 1;
					} else if (newIndex > $results.length) {
						newIndex = newIndex % $results.length;
					}

					$result = $results.eq(newIndex);

					$input.actions._setResultFocus($result);

					//$rootScope.$apply();
				},

				_selectResultOnEnter: function (e) {
					var $result;

					if (e.key && e.key.toLowerCase() === 'enter') {
						$result = $(e.target).closest(selectors.resultItem);

						if ($result.length === 0 || $result.is(selectors.resultItem) === false) {
							$result = $input.actions._getFocusEl();
						}

						if ($result.length !== 0 && $result.is(selectors.resultItem)) {
							e.preventDefault();
							$input.actions._selectResult($result, true);
						}
					}
				},

				_hideResultsOnEscape: function () {
					if ($input.state.resultsVisible === true) {
						$input.state.resultsVisible = false;
						//$rootScope.$apply();
					}
				},

				_onUnfocus: function (e) {
					var $hadFocus = $(e.target);
					var $hadFocusAutocomplete = $hadFocus.closest(selectors.autocomplete);

					// 1 ms timeout so we can check what element has focus after blur
					window.setTimeout(function () {
						var $hasFocus = $(document.activeElement);
						var inFocus = $hasFocus.closest($hadFocusAutocomplete).length !== 0;

						var $focusedResult = $input.actions._getFocusEl();

						if (inFocus === false) {
							if ($focusedResult.closest(selectors.resultItem).length > 0) {
								$input.actions._selectResult($focusedResult);
							} else {
								$input.actions._hideResults();
							}

							//$rootScope.$apply();
						}
					}, 1);
				},

				_onFocus: function (e) {
					var $focus = $input.actions._getFocusEl();
					var $autocomplete = $(e.target).closest(selectors.autocomplete);
					var $results = $autocomplete.find(selectors.results);

					var inFocus = $focus.closest(selectors.autocomplete).length !== 0;
					var resultsVisible = $input.state.resultsVisible;
					var hasResults = $input.state.results && $input.state.results.length;

					if (inFocus && !resultsVisible && hasResults) {
						$input.actions._showResults();
					}

					//$rootScope.$apply();
				},

				_selectResult: function ($result, focusOnInput) {
					var $autocomplete = $result.closest(selectors.autocomplete);
					var $input = $autocomplete.find(selectors.autocompleteInput);

					var address = $result.data(dataAttributes.address);
					var id = $result.data(dataAttributes.id);

					$input.actions.select(address, id);

					//$rootScope.$apply();

					if (focusOnInput) {
						$input.focus();

						// Sometimes, AngularJS seems to remove this focus when applied immediately
						window.setTimeout(function () {
							$input.focus();
						}, 50);
					}
				},

				// General utility
				_hideResults: function () {
					$input.state.resultsVisible = false;
				},

				_showResults: function () {
					// console.log($input.state.result);
					var numResults = $input.state.results ? $input.state.results.length : 0;

					if (numResults > 0) {
						publish('/assist/speak', numResults + 'result' + (numResults === 1 ? '' : 's'));

						// Ensure the above line is spoken before the results are read out
						window.setTimeout(function () {
							$input.state.resultsVisible = true;
							$input.state.selectedAutocompleteResult = $input.name + '-result-0';

							//$rootScope.$apply();
						}, 100);
					} else {
						$input.actions._removeResultFocus();
					}
				},

				_removeResultFocus: function () {
					$input.state.selectedAutocompleteResult = null;
				},

				_getFocusEl: function () {
					var $focusEl = $('#' + $input.state.selectedAutocompleteResult);
					return $focusEl;
				},

				_setResultFocus: function ($result) {
					var id = $result.attr('id');
					$input.state.selectedAutocompleteResult = id;
					//$rootScope.$apply();
				}
			}
		};

		//$input.update();

		$input.componentValidation = function (value) {
			var rules = $input.validation;

			//console.log('componentValidation', value);

			var hasValue;
			var propName;
			var prop;

			hasValue = false;

			for (propName in value) {
				prop = value[propName];
				if (!!prop) {
					hasValue = true;
					break;
				}
			}

			// Custom required validation
			if (rules.required) {
				if (hasValue === false) {
					return ErrorTypes.REQUIRED;
				}
			}

			// partial pattern validation
			var allPartsPass = true;
			var keys = Object.keys(partialPatterns);
			for (var i = 0; i < keys.length; i++) {
				var key = keys[i];

				var val = value[key];
				var reg = partialPatterns[key];

				if (val && val.length && reg) {
					// ctrl.input.errorParts
					// pattern

					if (reg.test(val) === false) {
						$input.errorParts[key] = 'pattern';
						//console.error('address', key, val);
						allPartsPass = false;
					} else {
						$input.errorParts[key] = null;
					}
				} else {
					$input.errorParts[key] = null;
				}
			}
			if (allPartsPass === false) {
				return ComponentErrorTypes.PART_PATTERN;
			}

			// If there is a value, check that all the required
			// parts of an address are present
			if (hasValue) {
				if (!(value.StreetNumber && value.StreetName && value.City && value.Postcode)) {
					return ComponentErrorTypes.INVALID;
				}
			}

			// If component is not required, but the value is incomplete, still fail validation
			// Is the value valid? Empty is required
			// Is there a value - if required?
			return true;
		}

		app.init();
	};

	export default {
		name: 'teraform-address',
		props: ['input'],
		data() {
			return {
				loaded: false,
			}
		},
		beforeMount() {
			//console.log('teraform-address beforeMount', this.input);
			setup(this.input);
		},
		mounted() {
			//console.log('teraform-address mounted', this.input);
			this.loaded = true;
		},
		methods: {
		}
	};
</script>